import {Delta, type InsertOp, isInsert, type MountedEditor} from '@avvoka/editor'
import { _getWhitelist, _isDisallowedCharacter } from "~/features/editor/character-validation";
import Utils, { getCharAndWordCount } from '../../features/utils'
import { toAttributeString, hasMalformedDefault } from './validation_utils'
import { uniqueArray } from '@avvoka/shared'

export enum ReportSeverity {
  Information,
  Warning,
  Error
}

export type ReportFunction = (severity: ReportSeverity, message: string) => void

/**
 * Validates the length of the document title against the defined character limits.
 * Reports an error if the title length is outside the allowed range.
 *
 * @param report - Function to report validation errors
 * @param title - The document title to validate
 */
const validateDocumentTitleLength = (report: ReportFunction, title: string) => {
  let { isSet, min: minTitleLength, max: maxTitleLength } = AvvStore.getters.titleCharacterLimit;
  if(!isSet) return true;

  const currentTitleLength = title.length;
  const finiteMaxTitleLength = maxTitleLength || Number.POSITIVE_INFINITY
  const isBelowMinimum = minTitleLength && currentTitleLength < minTitleLength
  const isAboveMaximum = maxTitleLength !== undefined && currentTitleLength > finiteMaxTitleLength

  if (isBelowMinimum || isAboveMaximum) {
    report(
      ReportSeverity.Error,
      localizeText(
        maxTitleLength !== false
          ? 'template.err_messages.invalid_title_length'
          : 'template.err_messages.invalid_title_length_no_max',
        { min: minTitleLength, max: maxTitleLength, current: currentTitleLength }
      )
    );
    return false
  }
  return true;
};

/**
 * Validates the length of the document body against the defined character limits.
 * Reports an error if the body length is outside the allowed range.
 *
 * @param report - Function to report validation errors
 * @param editor - The mounted editor instance containing the document body
 */
const validateDocumentBodyLength = (report: ReportFunction, editor: MountedEditor) => {
  let { isSet, min: minBodyLength, max: maxBodyLength } = AvvStore.getters.bodyCharacterLimit;
  if(!isSet) return true;

  const { chars: currentBodyLength } = getCharAndWordCount(editor.readonlyDelta.ops);
  const finiteMaxBodyLength = maxBodyLength || Number.POSITIVE_INFINITY
  const isBelowMinimum = minBodyLength && currentBodyLength < minBodyLength
  const isAboveMaximum = maxBodyLength !== undefined && currentBodyLength > finiteMaxBodyLength

  if (isBelowMinimum || isAboveMaximum) {
    report(
      ReportSeverity.Error,
      localizeText(
        maxBodyLength !== false
          ? 'template.err_messages.invalid_body_length'
          : 'template.err_messages.invalid_body_length_no_max',
        { min: minBodyLength, max: maxBodyLength, current: currentBodyLength }
      )
    );
    return false;
  }
  return true;
};

/**
 * Validates the characters in the document title against the defined whitelist.
 * Reports an error if any disallowed characters are found in the title.
 *
 * @param report - Function to report validation errors
 * @param title - The document title to validate
 */
const validateDocumentTitleCharacters = (report: ReportFunction, title: string) => {
  const whitelist = _getWhitelist();
  const { appliesToTitle } = AvvStore.getters.charactersWhitelist;

  if (whitelist.length > 0 && appliesToTitle) {
    const disallowedCharacters = uniqueArray(title.split('')).filter(char => _isDisallowedCharacter(char, whitelist));

    if (disallowedCharacters.length > 0) {
      report(
        ReportSeverity.Error,
        localizeText('template.err_messages.contains_nonwhitelisted_characters', {
          whitelist: whitelist.join(' '),
        })
      );
      return false;
    }
  }
  return true;
};


/**
 * Validates the characters in the document title against the defined whitelist.
 * Reports an error if any disallowed characters are found in the title.
 *
 * @param report - Function to report validation errors
 * @param content - The document delta
 */
const validateDocumentBodyCharacters = (report: ReportFunction, content: Delta) => {
  const whitelist = _getWhitelist();
  if (whitelist.length > 0) {
    const text = (content.ops as InsertOp[]).map(op => typeof op.insert === 'string' ? op.insert : '').join('')
    const disallowedCharacters = uniqueArray(text.split('')).filter(char => _isDisallowedCharacter(char, whitelist));

    if (disallowedCharacters.length > 0) {
      report(
        ReportSeverity.Warning,
        localizeText('documents.err_messages.body_contains_nonwhitelisted_characters', {
          whitelist: whitelist.join(' '),
        })
      );
      return false;
    }
  }
  return true;
};

/**
 * Validates various aspects of the document, including title length, body length, and title characters.
 * This function orchestrates the validation process by calling individual validation functions.
 *
 * @param report - Function to report validation errors
 * @returns Always returns true, indicating that validation checks have been performed
 *
 * @remarks
 * This function doesn't return false in case of validation errors. Instead, it reports errors
 * using the provided report function. The return value is meant to indicate that the validation
 * process has completed, not whether the document is valid.
 */
export function validateDocument(report: ReportFunction): boolean {
  // TODO: Move document validation to a separate file
  const state = AvvStore.state;

  const validations = [
    validateDocumentTitleLength(report, state.doc_name),
    validateDocumentTitleCharacters(report, state.doc_name),
  ]

  EditorFactory.mainOptional.ifPresent((editor) => {
    validations.push(
      validateDocumentBodyLength(report, editor),
      validateDocumentBodyCharacters(report, editor.readonlyDelta)
    )
  })

  return validations.every(item => item)
}

export function validateTemplate(report: ReportFunction) {
  const state = AvvStore.state

  const validateAllowedDomains = (): boolean => {
    const allowedDomains = AvvStore.getters.roles
      .map((party) => party.rights.allowed_email_domains)
      .filter((e) => e && e !== 'undefined')

    return allowedDomains.every((domains) => {
      const parsedDomains = domains.split(',').map((domain) => domain.trim())

      return parsedDomains.every((domain) =>
        /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/.test(domain)
      )
    })
  }

  void window.generateQuestionnaire()

  AvvStore.commit('SANITIZE_QUESTIONS')

  // Disallow to save when there is no template name
  if (!state.template_name) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_name'))
  }

  // Disallow to save when there is no < > in template name
  if (
    state.template_name.indexOf('>') !== -1 ||
    state.template_name.indexOf('<') !== -1
  ) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.forbidden_symbols')
    )
  }

  // Disallow to save when there is no defined party
  if (AvvStore.getters.roles.length <= 0) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_party'))
  }

  // Disallow to save party with empty/invalid value
  if (
    AvvStore.getters.roles
      .map((arr) => arr.party)
      .some((pName) => pName == null || pName.length <= 0)
  ) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.empty_invalid_party')
    )
  }

  // Disallow to save representative with empty/invalid value
  if (
    AvvStore.getters.roles
      .map((arr) => arr.name)
      .some((repName) => repName == null || repName.length <= 0)
  ) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.empty_invalid_role')
    )
  }

  // Disallow party and representative to be the same
  if (AvvStore.getters.roles.some((arr) => arr.party === arr.name)) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.same_party_role')
    )
  }

  // Disallow to save if there is no any "Start document as this user" checked
  if (!state.start_document_party.party || !state.start_document_party.role) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_author')
    )
  }

  const getPartiesWithoutController = () => {
    return Ast.uniqueArray(
      AvvStore.getters.roles
        .filter((party) =>
          AvvStore.getters.roles
            .filter((p) => p.party === party.party)
            .every((participant) => participant.rights.approve === 'No')
        )
        .map((p) => p.party)
    )
  }

  const partiesWithoutController = getPartiesWithoutController()
  if (partiesWithoutController.length) {
    const isMultiParty = partiesWithoutController.length > 1
    const joinedPartyString = partiesWithoutController.join(' and ')
    const verb = isMultiParty ? 'are' : 'is'

    report(
      ReportSeverity.Error,
      `${joinedPartyString} ${verb} missing a controller`
    )
  }

  if (!validateAllowedDomains()) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.invalid_domain')
    )
  }

  const attributes = AvvStore.getters.attributes.filter(
    (att) => att.length > 255
  )
  if (attributes.length) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.long_att', {
        attrs: attributes.map((attr) => `\`${attr}\``).join(' ,')
      })
    )
  }
}

export function validateQuestions(
  report: ReportFunction,
  questionScope: Backend.Questionnaire.IQuestion[]
) {
  const state = AvvStore.state

  // Validate label questions
  if (
    questionScope
      .filter(
        (question) => question.type !== 'label' && question.type !== 'section'
      )
      .some((question) => !question.att)
  ) {
    report(ReportSeverity.Error, localizeText('template.err_messages.no_att_q'))
  }

  // Validate metadata questions
  const metadataQuestionsWithoutKey = questionScope
    .filter(
      (question) => question.type === 'metadata' && !question.opts.metadata_key
    )
    .map((question) => question.att)
  if (metadataQuestionsWithoutKey.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.metadata', {
        attrs: metadataQuestionsWithoutKey.join(', ')
      })
    )
  }

  // Validate select-type questions
  const selectQuestions = questionScope.filter((question) =>
    ['select', 'open_select', 'multi_select'].includes(question.type)
  )

  const multiSelectQuestions = selectQuestions.filter(q => q.type === 'multi_select')

  const attributesWithMissingOptionValue = selectQuestions
    .filter((question) => {
      const options = question.opts
        .selectOptions as Backend.Questionnaire.ISelectOption[]
      return options && options.filter((option) => !option.value).length > 0
    })
    .map((question) => question.att)
    .join(', ')

  if (attributesWithMissingOptionValue.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_option_value', {
        attributes: attributesWithMissingOptionValue
      })
    )
  }

  const attributesWithMissingOptionLabel = selectQuestions
    .filter((question) => {
      const options = question.opts
        .selectOptions as Backend.Questionnaire.ISelectOption[]
      return (
        options &&
        options.filter(
          (option) =>
            option.type === 'collect' &&
            option.label_type === 'static' &&
            !option.label
        ).length > 0
      )
    })
    .map((question) => question.att)
    .join(', ')

  if (attributesWithMissingOptionLabel.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_option_label', {
        attributes: attributesWithMissingOptionLabel
      })
    )
  }

  const attributesWithDuplicateOptionValue = selectQuestions
    .filter((question) => {
      const options = question.opts
        .selectOptions as Backend.Questionnaire.ISelectOption[]
      return (
        options &&
        options.filter(
          (option) =>
            option.value &&
            options.filter(
              (opt) => opt.type === option.type && opt.value === option.value
            ).length > 1
        ).length > 0
      )
    })
    .map((question) => question.att)
    .join(', ')

  if (attributesWithDuplicateOptionValue.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_option_value_uniq', {
        attributes: attributesWithDuplicateOptionValue
      })
    )
  }

  const attributesWithDuplicateOptionLabel = selectQuestions
    .filter((question) => {
      const options = question.opts
        .selectOptions as Backend.Questionnaire.ISelectOption[]
      return (
        options &&
        options.filter((option) => {
          const filteredOptions =
            option.type !== 'collect' || option.label_type === 'static'
              ? options.filter(
                  (opt) =>
                    (opt.type !== 'collect' || opt.label_type === 'static') &&
                    (opt.label || opt.value) === (option.label || option.value)
                )
              : options.filter(
                  (opt) =>
                    opt.type === 'collect' &&
                    opt.label_type !== 'static' &&
                    (opt.label || opt.value) === (option.label || option.value)
                )

          return filteredOptions.length > 1
        }).length > 0
      )
    })
    .map((question) => question.att)
    .join(', ')

  if (attributesWithDuplicateOptionLabel.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_option_label_uniq', {
        attributes: attributesWithDuplicateOptionLabel
      })
    )
  }

  const attributeWithDefaultSeparatorInOption = multiSelectQuestions.filter(q => q.opts.selectOptions?.some(o => q.opts.defaultSeparator && o.value.includes(q.opts.defaultSeparator))).map(q => q.att).join(', ')

  if (attributeWithDefaultSeparatorInOption.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_option_default_separator', {
        attributes: attributeWithDefaultSeparatorInOption
      })
    )
  }

  const attributesWithoutDefaultSeparator = toAttributeString(multiSelectQuestions.filter(q => !q.opts.defaultSeparator))

  if (attributesWithoutDefaultSeparator.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_default_separator_defined', {
        attributes: attributesWithoutDefaultSeparator
      })
    )
  }

  const attributesWithMalformedDefaultValue = toAttributeString(multiSelectQuestions.filter(hasMalformedDefault))

  if (attributesWithMalformedDefaultValue.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.malformed_default', {
        attributes: attributesWithMalformedDefaultValue
      })
    )
  }

  // Validate datasheet questions
  const datasheetsQuestions = questionScope.filter(
    (question) => question.type === 'datasheets'
  )

  const attributesWithInvalidDatasheet = datasheetsQuestions
    .filter((question) => {
      const datasheet_id = question.opts.datasheet_id
      return (
        state.authorized_datasheet_ids.findIndex((id) => id == datasheet_id) ===
        -1
      )
    })
    .map((question) => question.att)
    .join(', ')

  if (attributesWithInvalidDatasheet.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_datasheet_id', {
        attributes: attributesWithInvalidDatasheet
      })
    )
  }

  // Validate list questions
  const listQuestions = questionScope.filter(
    (question) => question.type === 'listSelectDb'
  )

  const attributesWithInvalidList = listQuestions
    .filter((question) => {
      const name = question.opts.listSelectDbName
      return !state.templates_lists[name]
    })
    .map((question) => question.att)
    .join(', ')

  if (attributesWithInvalidList.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_list', {
        attributes: attributesWithInvalidList
      })
    )
  }

  // Validate dependent list questions
  const dependentListQuestions = questionScope.filter(
    (question) => question.type === 'dependentList'
  )

  const attributesWithInvalidDependentList = dependentListQuestions
    .filter((question) => {
      const name = question.opts.dependentListName
      return !state.templates_dependent_lists[name]
    })
    .map((question) => question.att)
    .join(', ')

  if (attributesWithInvalidDependentList.length > 0) {
    report(
      ReportSeverity.Error,
      localizeText('template.err_messages.no_att_dependent_list', {
        attributes: attributesWithInvalidDependentList
      })
    )
  }
}

export function validateWorkflows(
  report: ReportFunction,
  workflowScope: Backend.Workflows.Workflow[]
) {
  const state = AvvStore.state
  const attributes = AvvStore.getters.attributes

  // Ensure WFStore is defined
  if (typeof WFStore === 'undefined') {
    const currentTab = avv_get_current_tab()

    avv_select_tab('workflow')
    avv_select_tab(currentTab)
  }

  // Generic validators
  const validateRecipient = (recipient: Backend.Workflows.Recipient) => {
    if (!recipient) return true
    else if ('role' in recipient) return !(recipient.role && recipient.party)
    else if ('name_attribute' in recipient)
      return !(recipient.name_attribute && recipient.email_attribute)
    else if ('user_id' in recipient) return !recipient.user_id
    else if ('name' in recipient) return !(recipient.name && recipient.email)
  }

  // Validators
  const validateAddAttachmentRule = (rule: Backend.Workflows.Workflow) => {
    const { attachments } = rule.options
    if (!attachments || attachments.length === 0) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.add_remove_attachment.attachment_missing'
        )
      )
      return true
    }
  }

  const validateMoveToFolderRule = (rule: Backend.Workflows.Workflow) => {
    const { folder_id } = rule.options.move_to_folder
    if (!folder_id) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.move_to_folder.folder_missing'
        )
      )
      return true
    }
  }

  const validateAddRemoveDocumentLabelRule = (
    rule: Backend.Workflows.Workflow
  ) => {
    const { labels } = rule.options
    if (labels.length === 0) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.add_remove_document_label.label_missing'
        )
      )
      return true
    } else if (
      labels.some(
        (id: number) =>
          state.profileOrganisationLabels.findIndex(
            ({ id: _id }) => _id == id
          ) === -1
      )
    ) {
      report(
        ReportSeverity.Warning,
        localizeText(
          'template.err_messages.workflow.rule.add_remove_document_label.label_invalid'
        )
      )
      return true
    }
  }

  const validateSaveDocumentVersionRule = (
    rule: Backend.Workflows.Workflow
  ) => {
    const restrictedTriggers = [
      'deleted',
      'completed',
      'signed',
      'partially_signed',
      'pending_external_signature'
    ]

    if (restrictedTriggers.includes(rule.trigger)) {
      report(
        ReportSeverity.Error,
        localizeText('workflow.validation.cannot_trigger_document_save', {
          workflow: rule.label
        })
      )
      return true
    }
  }

  const validateSendReminderEmailRule = (rule: Backend.Workflows.Workflow) => {
    const { recipients, email_template_id } =
      rule.options.send_reminder_email ?? {}
    if (!Array.isArray(recipients) || recipients.length === 0) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.send_reminder_email.recipients_missing'
        )
      )
      return true
    } else if (!email_template_id) {
      report(
        ReportSeverity.Warning,
        localizeText(
          'template.err_messages.workflow.rule.send_reminder_email.template_missing'
        )
      )
      return true
    } else if (recipients.some(validateRecipient)) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.send_reminder_email.recipients_invalid'
        )
      )
      return true
    }
  }

  const validateSendDocumentRule = (rule: Backend.Workflows.Workflow) => {
    const { recipients, email_template_id } = rule.options.send_document ?? {}
    if (!Array.isArray(recipients) || recipients.length === 0) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.send_document.recipients_missing'
        )
      )
      return true
    } else if (!email_template_id) {
      report(
        ReportSeverity.Warning,
        localizeText(
          'template.err_messages.workflow.rule.send_document.template_missing'
        )
      )
      return true
    } else if (recipients.some(validateRecipient)) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.send_document.recipients_invalid'
        )
      )
      return true
    }
  }

  const validateAddParticipantRule = (rule: Backend.Workflows.Workflow) => {
    if (!rule.party_def) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.add_participant.participant_missing'
        )
      )
      return true
    } else if (
      WFStore.state.currEditedRule &&
      WFStore.getters.isApproverInitial !== WFStore.state.isApprover
    ) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.rule.add_participant.block_action_missing'
        )
      )
      return true
    }

    const data = rule.options.pre_defined_participants
    const mode = data.mode

    if (mode === 'default') {
      if (data.active) {
        report(
          ReportSeverity.Error,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.active_invalid'
          )
        )
        return true
      }
    } else if (mode === 'list' || mode === 'auto_list') {
      if (!data.emails) {
        report(
          ReportSeverity.Error,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.email_missing'
          )
        )
        return true
      } else if (
        data.emails
          .split(';')
          .some((email: string) => !Utils.isEmailValid(email.trim()))
      ) {
        report(
          ReportSeverity.Error,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.email_invalid'
          )
        )
        return true
      }
    } else if (mode === 'contact_group' || mode === 'auto_contact_group') {
      if (!data.contact_group.contact_group_id) {
        report(
          ReportSeverity.Warning,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.contact_group_missing'
          )
        )
        return true
      } else if (
        !state.activeContactGroups.some(
          (contactGroup) =>
            contactGroup.id == data.contact_group.contact_group_id
        )
      ) {
        report(
          ReportSeverity.Warning,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.contact_group_invalid'
          )
        )
        return true
      } else if (mode === 'contact_group' && !data.contact_group.pick_item) {
        report(
          ReportSeverity.Error,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.contact_group_pick_invalid'
          )
        )
        return true
      }
    } else if (mode === 'auto_attribute') {
      if (!data.emails || !attributes.includes(data.emails)) {
        report(
          ReportSeverity.Error,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.email_attribute_missing'
          )
        )
        return true
      } else if (!data.names || !attributes.includes(data.names)) {
        report(
          ReportSeverity.Error,
          localizeText(
            'template.err_messages.workflow.rule.add_participant.name_attribute_missing'
          )
        )
        return true
      }
    }
  }

  const validateLockClauseRule = (rule: Backend.Workflows.Workflow) => {
    if (
      !rule.options.lock_clause ||
      rule.options.lock_clause.clauses.length === 0
    ) {
      report(
        ReportSeverity.Error,
        localizeText('template.err_messages.lock_clause.clauses_empty')
      )
      return true
    }
  }

  const validateCreateRelatedDocumentRule = (
    rule: Backend.Workflows.Workflow
  ) => {
    const { related_template_id, related_template_participant_count } =
      rule.options
    if (!related_template_id) {
      report(
        ReportSeverity.Warning,
        localizeText(
          'template.err_messages.create_related_document.template_missing'
        )
      )
      return true
    } else if (
      related_template_participant_count &&
      related_template_participant_count != 0
    ) {
      const data = rule.options.pre_defined_participants
      const mode = data.mode

      if (!rule.party_def) {
        report(
          ReportSeverity.Error,
          localizeText(
            'template.err_messages.workflow.rule.create_related_document.party_role_missing'
          )
        )
        return true
      } else if (mode === 'auto_list') {
        if (!data.emails) {
          report(
            ReportSeverity.Error,
            localizeText(
              'template.err_messages.workflow.rule.create_related_document.email_missing'
            )
          )
          return true
        } else if (
          data.emails
            .split(';')
            .some((email: string) => !Utils.isEmailValid(email.trim()))
        ) {
          report(
            ReportSeverity.Error,
            localizeText(
              'template.err_messages.workflow.rule.create_related_document.email_invalid'
            )
          )
          return true
        }
      } else if (mode === 'auto_contact_group') {
        if (!data.contact_group.contact_group_id) {
          report(
            ReportSeverity.Warning,
            localizeText(
              'template.err_messages.workflow.rule.create_related_document.contact_group_missing'
            )
          )
          return true
        } else if (
          !state.activeContactGroups.some(
            (contactGroup) =>
              contactGroup.id == data.contact_group.contact_group_id
          )
        ) {
          report(
            ReportSeverity.Warning,
            localizeText(
              'template.err_messages.workflow.rule.create_related_document.contact_group_invalid'
            )
          )
          return true
        }
      } else if (mode === 'auto_attribute') {
        if (!data.emails || !attributes.includes(data.emails)) {
          report(
            ReportSeverity.Error,
            localizeText(
              'template.err_messages.workflow.rule.create_related_document.email_attribute_missing'
            )
          )
          return true
        } else if (!data.names || !attributes.includes(data.names)) {
          report(
            ReportSeverity.Error,
            localizeText(
              'template.err_messages.workflow.rule.create_related_document.name_attribute_missing'
            )
          )
          return true
        }
      }
    }
  }

  // Validator mapping
  const ruleValidators: Record<
    Backend.Workflows.Workflow['rule'],
    (rule: Backend.Workflows.Workflow) => boolean | undefined
  > = {
    'Add Attachment': validateAddAttachmentRule,
    'Add Participant': validateAddParticipantRule,
    'Save document version': validateSaveDocumentVersionRule,
    'Send reminder email': validateSendReminderEmailRule,
    'Send Document': validateSendDocumentRule,
    'Add Document Label': validateAddRemoveDocumentLabelRule,
    'Remove Document Label': validateAddRemoveDocumentLabelRule,
    'Create related document': validateCreateRelatedDocumentRule,
    'Lock Clause': validateLockClauseRule,
    'Move to folder': validateMoveToFolderRule,
  }

  const validateApprovalStatusTrigger = (rule: Backend.Workflows.Workflow) => {
    if (
      !rule.options.approval_trigger ||
      rule.options.approval_trigger.length === 0 ||
      rule.options.approval_trigger.some(
        (opt) => !opt.workflow || typeof opt.status !== 'boolean'
      )
    ) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.trigger.approval_status.workflow_missing'
        )
      )
      return true
    }
  }

  const validateLabelAddedRemovedTrigger = (
    rule: Backend.Workflows.Workflow
  ) => {
    if (!rule.options.trigger_label_id) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.trigger.label_added_removed.label_missing'
        )
      )
      return true
    } else if (
      state.profileOrganisationLabels.findIndex(
        ({ id: _id }) => _id == rule.options.trigger_label_id
      ) === -1
    ) {
      report(
        ReportSeverity.Error,
        localizeText(
          'template.err_messages.workflow.trigger.label_added_removed.label_invalid'
        )
      )
      return true
    }
  }

  const triggerValidators = {
    approval_status: validateApprovalStatusTrigger,
    label_removed: validateLabelAddedRemovedTrigger,
    label_added: validateLabelAddedRemovedTrigger
  }

  const validateRule = (rule: Backend.Workflows.Workflow) => {
    if (!rule.label) {
      report(
        ReportSeverity.Error,
        localizeText('template.err_messages.workflow.label_missing')
      )
      return true
    } else if (!rule.trigger) {
      report(
        ReportSeverity.Error,
        localizeText('template.err_messages.workflow.trigger_missing')
      )
      return true
    } else if (!rule.rule) {
      report(
        ReportSeverity.Error,
        localizeText('template.err_messages.workflow.rule_missing')
      )
      return true
    } else if (triggerValidators[rule.trigger]?.(rule)) {
      return true
    } else return ruleValidators[rule.rule]?.(rule)
  }

  // Use some method to short circuit
  workflowScope.some((rule) => validateRule(rule))
}

export const documentValidationDialog = function (
  callback: (valid: boolean) => void,
  allowToProceedWarnings: boolean
) {
  const reports: { message: string; severity: ReportSeverity }[] = []
  
  const report = (severity: ReportSeverity, message: string) => {
    reports.push({ severity, message })
  }

  const isDocumentValid = validateDocument(report)

  if (reports.length > 0) {
    const messageEntries = reports.map(({ message, severity }) => {
      const icon =
        severity === ReportSeverity.Error
          ? 'error'
          : severity === ReportSeverity.Warning
            ? 'warning'
            : 'info'

      return `<div><i class="material-symbols-outlined float-left mr-1" aria-hidden="true">${icon}</i> ${message}</div>`
    })

    const message = `
      <div class="flex flex-col gap-1" style="max-height: 60vh;">
        ${messageEntries.join('')}
      </div>
      <br>
    `

    if (
      allowToProceedWarnings &&
      reports.every((error) => error.severity !== ReportSeverity.Error)
    ) {
      avv_dialog({
        confirmTitle: localizeText('template.dialog.validation.title'),
        confirmMessage: message,
        okButtonText: localizeText('template.dialog.validation.continue'),
        confirmCallback: callback
      })
    } else {
      avv_dialog({
        alertTitle: localizeText('template.dialog.validation.title'),
        alertMessage: message
      })

      callback(false)
    }
  } else {
    callback(isDocumentValid ?? true)
  }
}
  
export const validationDialog = function (
  callback: (valid: boolean) => void,
  ignoreWarnings: boolean,
  validateCurrentWorkflow: boolean
) {
  const detectedErrors: { message: string; severity: ReportSeverity }[] = []

  const report = (severity: ReportSeverity, message: string) => {
    detectedErrors.push({ severity, message })
  }

  // Validate generic template stuff
  validateTemplate(report)

  // Validate questions
  const questionScope = AvvStore.getters.nonDeletedQuestions
  validateQuestions(report, questionScope)

  // Validate currently selected or all workflows depending on what action we want to carry out
  const workflowScope =
    validateCurrentWorkflow && WFStore.state.currEditedRule
      ? Array.isArray(WFStore.state.currEditedRule)
        ? WFStore.state.currEditedRule
        : [WFStore.state.currEditedRule]
      : AvvStore.state.rules
  validateWorkflows(report, workflowScope as Backend.Workflows.Workflow[])

  if (detectedErrors.length > 0) {
    const messageEntries = detectedErrors.map(({ message, severity }) => {
      const icon =
        severity === ReportSeverity.Error
          ? 'error'
          : severity === ReportSeverity.Warning
            ? 'warning'
            : 'info'

      return `<div><i class="material-symbols-outlined float-left mr-1" aria-hidden="true">${icon}</i> ${message}</div>`
    })

    const message = `
      <div class="flex flex-col gap-1" style="max-height: 60vh;">
        ${messageEntries.join('')}
      </div>
      <br>
    `

    if (
      ignoreWarnings &&
      detectedErrors.every((error) => error.severity !== ReportSeverity.Error)
    ) {
      avv_dialog({
        confirmTitle: localizeText('template.dialog.validation.title'),
        confirmMessage: message,
        okButtonText: localizeText('template.dialog.validation.continue'),
        confirmCallback: callback
      })
    } else {
      avv_dialog({
        alertTitle: localizeText('template.dialog.validation.title'),
        alertMessage: message
      })

      callback(false)
    }
  } else {
    callback(true)
  }
}
